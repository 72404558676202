import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Angel = () => {
	const { angel: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Angel Li`]}
				description="About Dr Angel Li"
			/>
			<ProfilePage
				title="Dr Angel Li - Gastroenterologist"
				name="Dr Angel Li"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of general and luminal gastroenterology including inflammatory bowel disease"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Fiona Stanley Hospital, Perth, Western Australia",
				]}
				description={[
					"Angel is a Gastroenterologist who is passionate about gut health and providing holistic care to her patients. She has subspecialty interests in general and luminal gastroenterology, including inflammatory bowel disease, functional gastrointestinal disorders, coeliac disease, eosinophilic oesophagitis and nutrition.",
					"Angel completed her Physician training in Perth and undertook two clinical Fellowships in Inflammatory Bowel Disease at Fiona Stanley Hospital, where she gained experience treating complex inflammatory bowel disease, treating pregnant patients with inflammatory bowel disease and caring for adolescent patients transitioning from paediatric care. She is interested in gut microbiota and has been involved in research into faecal microbiota transplantation for the treatment of clostridium difficile colitis.",
					"Angel is committed to providing high quality gastroscopy and colonoscopy. She is also experienced with dye-spray chromoendoscopy.",
					"Angel speaks fluent Chinese Mandarin.",
				]}
				honours={[
					"MBBS (Hons) 2013",
				]}
				background="Angel completed her medical and specialist training in Western Australia. She graduated from the University of Western Australia with MBBS (Hons)."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
				]}
				memberships={[
					"The Royal Australasian College of Physicians (RACP)",
					"Conjoint Committee for the Recognition of Training in Gastrointestinal Endoscopy",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"European Crohn’s & Colitis Organisation",
					"The Gastroenterology Network of Intestinal Ultrasound (GENIUS)",
				]}
				personal={[
					"I grew up in Perth and enjoy spending time with family and friends. I am a mum of two and am always on the lookout for cool playgrounds and kids activities. I enjoy pilates, cooking and exploring the great outdoors.",
				]}
				publications={[
					{
						publicationTitle: "The efficacy, safety and need of faecal microbiota transplantation for the treatment of recurrent or relapsing clostridium difficile infection in WA",
						authors: "Li A, Makanyanga J, Manning L, Waters O",
					},
					{
						publicationTitle: "Prevalence and risk factors for early medical and surgical complications following an admission for acute severe ulcerative colitis (abstract)",
						authors: "Li A, Coote M, Thin L",
					},
					{
						publicationTitle: "Causes and timing of delayed bleeding after oral surgery",
						authors: "Czembirek C, Poeschl WP, Eder-Czembirek C, Fischer MB,  Perisanidis C, Jesch P, Schicho K, Li A, Seemann R",
					},
				]}
			/>
		</>
	);
};

export default Angel;
